import L from 'leaflet';
import 'leaflet.markercluster';
import GestureHandling from "leaflet-gesture-handling";

export default {
	init() {
    if (document.getElementsByClassName('vy_map_wrapper').length > 0) {
      preparMap();
    }
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};

function initMap(mapinfo) {
  L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

  const myLat = mapinfo.datalat;
  const myLng = mapinfo.datalng;

  const map = L.map(mapinfo.id, {
    center: [myLat, myLng],
    gestureHandling: true,
    maxZoom: 22,
    gestureHandlingOptions: {
      text: {
        touch: mapinfo.text_touch,
        scroll: mapinfo.text_scroll,
        scrollMac: mapinfo.text_scrollmac,
      },
    },
    zoom: mapinfo.zoom,
  });

  if(mapinfo.style){
    L.tileLayer(
      'https://api.mapbox.com/styles/'+mapinfo.mapbox.version+'/'+mapinfo.mapbox.name+'/'+mapinfo.style+'/tiles/256/{z}/{x}/{y}@2x?access_token='+mapinfo.mapbox.token
    ).addTo(map);
  } else {
    L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
  }

  const myIcon = L.divIcon({
    className: "vy_map_icon",
  });

  if (mapinfo.multiple_markers) {
    // MULTIPLE MARKERS
    let markers = L.markerClusterGroup({ maxClusterRadius: 80 });
    let markers_latlngs = [];

    // Function to update markers
    const updateMarkers = () => {
      // Clear existing markers
      markers.clearLayers();
      markers_latlngs = [];

      // Recheck markers_object
      const markers_object = $(mapinfo.multiple_markers + '[data-visible="1"]');

      console.log(markers_object);


      markers_object.each(function () {
        const markerLat = $(this).data("lat");
        const markerLng = $(this).data("lng");

        if (markerLat && markerLng) {
          const markerIcon = $(this).attr("data-class")
            ? L.divIcon({
                className: "vy_map_icon --" + $(this).data("class"),
              })
            : myIcon;

          const marker = L.marker([markerLat, markerLng], { icon: markerIcon });

          // Prepare marker popup content
          const content_title = $(this).data("title") || "";
          const content_address = $(this).data("address") || "";
          const content_phone = $(this).data("phone") || "";
          const content_website = $(this).data("website") || "";
          const content_website_title = $(this).data("website-title") || "";
          const content_website_target = $(this).data("website-target") || "_blank";
          const content_seemap = mapinfo.text_seemap;

          let content =
            '<div class="vy_map_popup_content uk-background-default uk-card uk-card-small uk-card-body">';
          if (content_title) {
            content += `<h4 class="vy_map_popup_content_title uk-h5">${content_title}</h4>`;
          }
          if (content_address) {
            content += `<p class="vy_map_popup_content_address">${content_address}</p>`;
          }
          if (content_phone) {
            content += `<p class="vy_map_popup_content_phone">${content_phone}</p>`;
          }
          content += `<div class="vy_map_popup_content_links uk-grid uk-grid-small" data-uk-grid>`;
          if (content_website) {
            content += `<div><a target="${content_website_target}" href="${content_website}" class="uk-button uk-button-secondary uk-button-small">${content_website_title}</a></div>`;
          }
          content += `<div><a target="_blank" href="https://maps.google.com/?daddr=${markerLat},${markerLng}" class="uk-button uk-button-primary uk-button-small">${content_seemap}</a></div>`;
          content += `</div>`;
          content += `</div>`;

          marker.bindPopup(content);

          markers.addLayer(marker);
          markers_latlngs.push(marker.getLatLng());
        }
      });

      // Update map bounds
      if (markers_latlngs.length > 1) {
        const bounds = new L.LatLngBounds(markers_latlngs).extend();
        map.fitBounds(bounds.pad(0.5));
      } else {
        const soloMarker = markers_latlngs[0];
        map.setView(soloMarker, mapinfo.zoom);
      }

      // Add markers back to the map
      map.addLayer(markers);
    };

    // Initial marker setup
    updateMarkers();

    // Add reset button functionality
    $(".js_map_reset-" + mapinfo.uid).on("click", function () {
      console.log('reset');

      updateMarkers(); // Recheck and update markers
    });
  } else {
    // SIMPLE MARKER
    const marker = L.marker([myLat, myLng], { icon: myIcon });
    marker.addTo(map).on("click", function () {
      window.open("https://maps.google.com/?daddr=" + myLat + "," + myLng);
    });
  }
}


window.preparMap = function () {
  $('.vy_map_wrapper').each(function () {
    var datalat = '';
    var datalng = '';
    var uid = '';
    var id = $(this).attr('id');
    var zoom = 10;
    var style = false;
    var multiple_markers = false;
    var text_seemap = 'Voir la carte';
    var text_touch = 'Utilisez deux doigts pour déplacer la carte';
    var text_scroll = 'Utilisez ctrl + scroll pour zoomer sur la carte';
    var text_scrollmac = 'Utilisez \u2318 + scroll pour zoomer sur la carte';

    datalat = $(this).data('lat');
    datalng = $(this).data('lng');

    if ($(this).attr('data-uid')) { uid = $(this).data('uid');}
    if ($(this).attr('data-pin')) { pin = $(this).data('pin');}
    if ($(this).attr('data-style')) { style = $(this).data('style');}
    if ($(this).attr('data-zoom')) { zoom = parseInt($(this).data('zoom'));}
    if ($(this).attr('data-text-touch')) { text_touch = $(this).data('text-touch');}
    if ($(this).attr('data-text-scroll')) { text_scroll = $(this).data('text-scroll');}
    if ($(this).attr('data-text-scrollmac')) { text_scrollmac = $(this).data('text-scrollmac');}
    if ($(this).attr('data-text-seemap')) { text_seemap = $(this).data('text-seemap');}
    if ($(this).attr('data-multiple-marker')) {multiple_markers = $(this).data('multiple-marker');}
    var mapinfo = { text_touch:text_touch,text_seemap:text_seemap,style:style,text_scroll:text_scroll,text_scrollmac:text_scrollmac,datalat: datalat, datalng: datalng, uid: uid, id: id, zoom: zoom,multiple_markers: multiple_markers,mapbox:{version:mapbox.version,name:mapbox.name,token:mapbox.token}};
    initMap(mapinfo);
  });
}
