import { ready } from 'jquery';
import lottieWeb from 'lottie-web';
import UIkit from 'uikit';
import Lenis from 'lenis';

// SCROLL
const lenis = new Lenis({lerp: 0.07})

// lenis.on('scroll', (e) => {
//   updateTransform();
// })

function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)

UIkit.util.on('[data-uk-modal]', 'show', function (e) {
  lenis.stop();
});
UIkit.util.on('[data-uk-modal]', 'hide', function (e) {
  lenis.start();
});
$(document).on('beforescroll', "body", function() {
  lenis.stop();
  lenis.start();
});

// SCROLL TO HASH
function scrollToHash() {
  var hash = window.location.hash;
  if (hash) {
      var target = jQuery(hash);
      if (target.length) {
          UIkit.scroll(target).scrollTo(target);
      }
  }
}
// ANIM SVG
var animation = new Array();

function loadBMAnimation(element,i) {

  var reverseLoop = Boolean($(element).data('reverseloop'));
  let dir = 1;
  var loopSetting = reverseLoop ? false : Boolean($(element).data('loop'));
  var hoverSetting = Boolean($(element).data('hover'));
  var repeatSetting = hoverSetting ? false : Boolean($(element).data('repeat'));

  var animation_params = {
    name: 'animation ' + i,
    container: element,
    renderer: "svg",
    loop: loopSetting,
    autoplay: Boolean($(element).data('autoplay')),
    path: $(element).data('path')
  };
  if ($(element).attr('data-preserveaspectratio')) { animation_params['rendererSettings'] = { preserveAspectRatio:$(element).data('preserveaspectratio') } }
  animation[i] = lottieWeb.loadAnimation(animation_params);

  var util = UIkit.util;
  var el = util.$(element);
  UIkit.scrollspy(el, { repeat: true });

  if(!hoverSetting){
    util.on(el,'inview', function(){
      animation[i].play();
    });
  }

  if(repeatSetting){
    util.on(el,'outview', function(){
      animation[i].stop();
    });
  }

  animation[i].addEventListener('DOMLoaded', function () {
    $(element).addClass('--loaded');
  });
  animation[i].addEventListener('enterFrame', function (event) {
    if (event.currentTime < 1 && !$(element).hasClass('--playing')) {
      $(element).addClass('--playing');
    }
  });

  animation[i].addEventListener('complete', function () {
    $(element).removeClass('--playing');

    if (!loopSetting) {
      $(element).addClass('--finish');
    }
    if (reverseLoop) {
      if(dir == 1) {dir = '-1'} else {dir = '1'; }
      animation[i].setDirection(dir);
      animation[i].play();
    }
  });
  animation[i].addEventListener('loopComplete', function () {
    $(element).removeClass('--playing');
    void $(element)[0].offsetWidth;
    $(element).addClass('--playing');
  });

  if (hoverSetting) {
    $($(element).data('hover')).on('mouseenter', function() {
      animation[i].pause();
      animation[i].setDirection(1);
      animation[i].play();
    });

    $($(element).data('hover')).on('mouseleave', function() {
      animation[i].pause();
      animation[i].setDirection(-1);
      animation[i].play();
    });
  }
}


$(function() {
  //load all animations json
  var element = document.getElementsByClassName("js_animation");

  for (var i = 0; i < element.length; i++) {
    loadBMAnimation(element[i],i);
  }

  //fix scroll to hash Lenis using Uikit
  scrollToHash();
});
